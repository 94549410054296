@tailwind base;
@tailwind components;
@tailwind utilities;

.rdp-caption_dropdowns {
  display: flex;
}
.rdp-caption_dropdowns .rdp-dropdown {
  margin-right: 10px;
  background-color: rgb(240, 240, 240); /* Light theme color */
}

.dark .rdp-caption_dropdowns .rdp-dropdown {
  background-color: rgb(10, 10, 10); /* Dark theme color */
}
.rdp-caption_dropdowns .rdp-vhidden {
  display: none;
}

:root {
  --highlight-color: 277, 100%, 65%;
  --highlight-hover-color: 277, 100%, 55%;
  --dark-highlight-color: 277, 70%, 20%;
  --text-color: 0, 0%, 0%;
  --inverted-text-color: 0, 0%, 93%;
  --gray-text: 0, 0%, 70%;
  --dark-gray-text: 0, 0%, 48%;
  --gray-icon-color: 0, 0%, 60%;
  --gray-icon-hover-color: 0, 0%, 72%;
  --ul-color: 0, 0%, 96%;
  --light-ui-color: 0, 0%, 85%;
  --ui-bevel: 0, 0%, 80%;
  --separator-color: 0, 0%, 73%;
  --panel-background: 0, 0%, 89%;
  --container-background: 0, 0%, 93%;
  --input-background: 0, 0%, 98%;
  --void-gap: 0, 0%, 100%;
  --status-success: 126, 71%, 54%;
  --status-success-hover: 126, 82%, 40%;
  --status-progress: 220, 100%, 65%;
  --status-failure: 0, 100%, 65%;
  --status-failure-hover: 0, 100%, 40%;
  --status-warning: 32, 100%, 65%;
  --album-color: 148, 100%, 65%;
  --smart-folder-color: 202, 100%, 65%;
  --upload-color: 32, 100%, 65%;
  --toolbar-bevel: 0, 0%, 97%;
  --toolbar-icon: 0, 0%, 30%;
}

.dark {
  --highlight-color: 277, 100%, 65%;
  --highlight-hover-color: 277, 100%, 55%;
  --dark-highlight-color: 277, 70%, 20%;
  --text-color: 0, 0%, 100%;
  --inverted-text-color: 0, 0%, 7%;
  --gray-text: 0, 0%, 70%;
  --dark-gray-text: 0, 0%, 52%;
  --gray-icon-color: 0, 0%, 60%;
  --gray-icon-hover-color: 0, 0%, 72%;
  --ul-color: 0, 0%, 13%;
  --light-ui-color: 0, 0%, 20%;
  --ui-bevel: 0, 0%, 20%;
  --separator-color: 0, 0%, 27%;
  --panel-background: 0, 0%, 11%;
  --container-background: 0, 0%, 7%;
  --input-background: 0, 0%, 7%;
  --void-gap: 0, 0%, 0%;
  --status-success: 126, 71%, 54%;
  --status-success-hover: 126, 82%, 40%;
  --status-progress: 220, 100%, 65%;
  --status-failure: 0, 100%, 65%;
  --status-failure-hover: 0, 100%, 40%;
  --status-warning: 32, 100%, 65%;
  --album-color: 148, 100%, 65%;
  --smart-folder-color: 202, 100%, 65%;
  --upload-color: 32, 100%, 65%;
  --toolbar-bevel: 0, 0%, 18%;
  --toolbar-icon: 0, 0%, 70%;
}
